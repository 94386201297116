<template>

  <div class="section-content-charm">
    <div class="charm-settings-sticky">

      <!-- Charm settings header -->
      <hb-header one-action :padding="false">

        <!-- property tab -->
        <template v-slot:left>
          <hb-tabs v-if="tabDetails.length" class="mt-n2">
            <v-tab v-for="item in tabDetails" :key="item.key" :href="'#tab-' + item.key" :ripple="false">
              {{ item.label }}
            </v-tab>
          </hb-tabs>
        </template>

        <!-- actions icon -->
        <template v-slot:right v-if="propertyId">
          <div class="pr-2">
            <hb-btn icon :tooltip="!$vuetify.breakpoint.xs ? 'Sidebar' : ''" :class="{
              'mr-3': $vuetify.breakpoint.mdAndDown && !$vuetify.breakpoint.xs,
            }" @click="showAside = !showAside" :active-by-default="true"
              :active-state-off="!showAside">mdi-table-actions-custom-1</hb-btn>
          </div>
        </template>
      </hb-header>
      <HbPropertySelector v-model="propertyId" :items="getProperties()" item-value="id" item-text="name"
        :loading="false" :disabled="false" />
      <v-divider class="mt-4"></v-divider>

    </div>



    <!-- if property not selected -->
    <div v-if="!propertyId" class="pa-4">
      <HbEmptyState message="Please select a Property to continue." />
    </div>

    <!-- loading  -->
    <v-row v-if="loadingDocuments" class="mx-0">
      <v-col md="12" class="text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>

    <!-- property selected -->
    <div v-else-if="propertyId && !loadingDocuments" class="primary-section-content-charm" :class="'height-auto'">
      <div :style="WidthComp" class="pl-0">

        <!-- expansion panels -->
        <v-expansion-panels v-model="panel" multiple class="pa-4">


          <!-- Voicemail Setup expansion-panel -->
          <hb-expansion-panel v-show="selectedMenuOption == 'voicemail'">
            <template v-slot:title>
              Voicemail Setup
            </template>
            <template v-slot:content>
              <!-- <hb-form label="Ring Time*" editable :active.sync="active" @cancel="handleEmittedCancel"
                @save="handleEmittedSave('default.ring_time')" :empty="!ringTime" :disable-save="true" full>
                <template v-slot:display>
                  As you have configured, your calls will go to voicemail after ringing for
                  <strong>{{ ringTime }}</strong>
                  seconds.
                </template>
                <template v-slot:edit>
                  <p>Calls will be sent to voicemail if no Call Forwarding Rules are set or if the call comes in outside
                    of the forwarding hours.
                    *Select the amount of time the phone will ring before being sent to voicemail.
                  </p>

                  <HbTextField disabled class="mt-5" condensed box small v-model="ringTime"
                    v-validate="'required|numeric|max_value:30'" data-vv-scope="default" data-vv-name="ring_time"
                    data-vv-as="Ring Time" :error="errors.has('default.ring_time')" placeholder="0" />
                </template>
              </hb-form> -->


              <hb-form label="Voicemail Greeting*" editable :active.sync="active" @cancel="handleEmittedCancel"
                @save="handleEmittedSave('default.greeting_message')" :empty="!GreetingMessage"
                :disable-save="disableSave" full>
                <template v-slot:display>
                  {{ defaultGreetingMessage }}
                </template>
                <template v-slot:edit>
                  <p style="color: #637381;">Provide a greeting message you would like to hear played at this property.</p>
                  <HbTextarea class="pt-3" v-model="GreetingMessage" placeholder="Greeting Message" v-validate="'required|max:999'"
                    data-vv-name="greeting_message" data-vv-as="Greeting Message" data-vv-scope="default" :rows="1"
                    :error="errors.has('default.greeting_message')" />
                  <textToSpeech :text="GreetingMessage" :selectedVoice="defaultVoiceGender">

                  </textToSpeech>
                </template>
              </hb-form>
            </template>
          </hb-expansion-panel>

          <!-- twilio studio flow Auto Attendant  -->
          <hb-expansion-panel v-show="selectedMenuOption == 'auto_attendant' && hasRolePermission('charm_auto_attendant')">
            <template v-slot:title>
              Auto Attendant
            </template>
            <template v-slot:actions>
              <span> Enable for this Property</span>
              <hb-switch  v-model="isAutoAttendantEnabled" :right="true"
                @click.stop.prevent="autoAttendant" :readonly="false" />
            </template>
            <template v-slot:content>
              <hb-form label="Greeting Message*" editable :active.sync="active" @cancel="handleEmittedCancel"
                @save="handleEmittedSave('default.greeting_message_studio')" :empty="!greeting_message_auto_attendant"
                :disable-save="disableSave" full hideActions>
                <template v-slot:display>
                  {{ greeting_message_auto_attendant }}
                </template>
                <template v-slot:edit>
                  <p style="color: #637381;">Provide a greeting message you would like to hear played at this property.</p>
                  <HbTextarea class="pt-3" v-model="greeting_message_auto_attendant" placeholder="Greeting Message"
                    v-validate="'required|max:999'" data-vv-name="greeting_message_studio" data-vv-as="Greeting Message"
                    data-vv-scope="default" :rows="1" :error="errors.has('default.greeting_message_studio')" />
                  <textToSpeech :text="greeting_message_auto_attendant" :selectedVoice="defaultVoiceGender">

                  </textToSpeech>

                </template>
              </hb-form>



              <!-- Menu Setup -->
              <hb-form label="Menu Setup" :active.sync="active" @cancel="handleEmittedCancel"
                :disable-save="disableSave" full hideActions
                hide-details>
                <template>
                  <p style="color: #637381;" class="pb-4 pl-2 pt-1 ">Setup the options and order of the IVR menu.</p>
                  <draggable :list="auto_attendant_menu" @update="saveNewOrder">
                    <div class="panel-wrapper" v-for="(item, index) in auto_attendant_menu" :key="item.id">
                      <span class="circle margin-top"><b style="color: black;">{{ index + 1 }}</b></span>
                      <hb-btn class="margin-top-2" icon tooltip="Drag" active-state-off>
                        mdi-drag-custom
                      </hb-btn>
                      <div class="panel-container">
                        <hb-expansion-panel>
                          <template v-slot:title>
                            {{ item.action_name }} {{ item.title?.trim() ? `(${item.title})` : "" }}
                          </template>
                          <template v-slot:content>
                            <div class="pa-5">
                              <div class="d-flex flex-column">
                                <div class="pb-4">
                                  <span style="color: #637381;">Action</span>
                                  <HbSelect v-model="item.action_id" :items="auto_attendant_options"
                                    item-text="action_name" item-value="id" label="Select Action" full
                                    @change="id => updateList(id, item.id,item.uniqueId)" />
                                </div>

                                <div class="pb-4" v-if="item.action == 'ring_phone' || item.action == 'text_to_speech'">
                                  <span style="color: #637381;">Title</span>
                                  <HbTextarea v-model="item.title" class="pt-2" full></HbTextarea>
                                </div>

                                <div v-if="!item.new" class="pb-4">
                                  <span style="color: #637381;">Call to Action</span>
                                  <div style="display: flex; align-items: flex-end;">
                                    <HbTextarea v-model="item.call_to_action" class="pt-2" full style="flex: 1;">
                                    </HbTextarea>
                                    <span style="margin-left: 10px; vertical-align: super; line-height: 1;"><strong>,
                                        press {{ index + 1 }}</strong></span>
                                  </div>
                                </div>
                                <textToSpeech v-if="!item.new"
                                  :text="`${item.call_to_action}` + ' press ' + `${index + 1}`" :selectedVoice="defaultVoiceGender">

                                </textToSpeech>

                                <div class="pb-4" v-if="item.action == 'text_to_speech'">
                                  <span style="color: #637381;">Text to Speech</span>
                                  <HbTextarea v-model="item.text_to_speech" class="pt-2" full></HbTextarea>
                                </div>
                                <textToSpeech v-if="item.action == 'text_to_speech'" :text="`${item.text_to_speech}`" :selectedVoice="defaultVoiceGender">

                                </textToSpeech>

                              </div>
                            </div>

                          </template>
                        </hb-expansion-panel>
                      </div>
                      <span class="margin-top-2">
                        <HbBtn icon tooltip="Close" @click="removeItem(item)" mdi-code="mdi-close-circle" />
                      </span>
                    </div>
                  </draggable>

                  <hr style="margin-top: 10px; border: none; border-top: 1px solid #DFE3E8;">
                  <hb-link text @click="addNewOption">
                    + Add Menu Option
                  </hb-link>
                  <hr style="margin-top: 10px; border: none; border-top: 1px solid #DFE3E8;">
                  <div style="display: flex; align-items:center">
                    <span class="circle margin-top"><b style="color: black;">9</b></span>
                    <strong class="pt-3 hb-font-body-medium">To repeat this menu, press 9.</strong>
                  </div>
                  <div style="display: flex; align-items:center">
                    <span class="circle margin-top"><b style="color: black;">0</b></span>
                    <strong class="pt-3 hb-font-body-medium">To speak with an Agent, press 0.</strong>
                  </div>
                </template>
              </hb-form>


              <!-- alternate menu for tenant -->
              <div class="pa-3" style="display: flex; align-items: center;">
                <HbSwitch @click="alternateMenuTenantToggle" v-model="alternateMenuTenant" />
                <p style="margin-left: 2px;margin:0%" class="hb-font-body-medium">Set alternate menu order for Tenant callers</p>
              </div>
              <div v-if="alternateMenuTenant" class="alternateTenantCard">
                 <p class="pt-3 pl-3" style="color: #637381;">Prioritize tenant-specific actions for calls from phone numbers linked to tenant profiles. For example, list Phone Payment as the first option.</p> 
              </div>
              <hb-form v-if="alternateMenuTenant" label="Current Tenant Menu Setup" :active.sync="active" @cancel="handleEmittedCancel"
                :disable-save="disableSave" full hideActions
                hide-details>
                <template>
                  <draggable :list="auto_attendant_menu_tenant" @update="saveNewOrderTenant">
                    <div class="panel-wrapper" v-for="(item, index) in auto_attendant_menu_tenant" :key="item.id">
                      <span class="circle margin-top"><b style="color: black;">{{ index + 1 }}</b></span>
                      <hb-btn class="margin-top-2" icon tooltip="Drag" active-state-off>
                        mdi-drag-custom
                      </hb-btn>
                      <div class="panel-container">
                        <hb-expansion-panel>
                          <template v-slot:title>
                            {{ item.action_name }} {{ item.title?.trim() ? `(${item.title})` : "" }}
                          </template>
                          <template v-slot:content>
                            <div class="pa-5">
                              <div class="d-flex flex-column">
                                <div class="pb-4">
                                  <span style="color: #637381;">Action</span>
                                  <HbSelect readonly :clearable="false" v-model="item.action_id" :items="auto_attendant_options"
                                    item-text="action_name" item-value="id" label="Select Action" full
                                    @change="id => updateList(id, item.id,item.uniqueId)" />
                                </div>

                                <div class="pb-4" v-if="item.action == 'ring_phone' || item.action == 'text_to_speech'">
                                  <span style="color: #637381;">Title</span>
                                  <HbTextarea readonly v-model="item.title" class="pt-2" full></HbTextarea>
                                </div>

                                <div v-if="!item.new" class="pb-4">
                                  <span style="color: #637381;">Call to Action</span>
                                  <div style="display: flex; align-items: flex-end;">
                                    <HbTextarea readonly v-model="item.call_to_action" class="pt-2" full style="flex: 1;">
                                    </HbTextarea>
                                    <span style="margin-left: 10px; vertical-align: super; line-height: 1;"><strong>,
                                        press {{ index + 1 }}</strong></span>
                                  </div>
                                </div>
                                <textToSpeech v-if="!item.new"
                                  :text="`${item.call_to_action}` + ' press ' + `${index + 1}`" :selectedVoice="defaultVoiceGender">

                                </textToSpeech>

                                <div class="pb-4" v-if="item.action == 'text_to_speech'">
                                  <span style="color: #637381;">Text to Speech</span>
                                  <HbTextarea readonly v-model="item.text_to_speech" class="pt-2" full></HbTextarea>
                                </div>
                                <textToSpeech v-if="item.action == 'text_to_speech'" :text="`${item.text_to_speech}`" :selectedVoice="defaultVoiceGender">
                                </textToSpeech>
                              </div>
                            </div>
                          </template>
                        </hb-expansion-panel>
                      </div>
                    </div>
                  </draggable>
 
                  <hr style="margin-top: 10px; border: none; border-top: 1px solid #DFE3E8;">
                  <div style="display: flex; align-items:center">
                    <span class="circle margin-top"><b style="color: black;">9</b></span>
                    <strong class="pt-3 hb-font-body-medium">To repeat this menu, press 9.</strong>
                  </div>
                  <div style="display: flex; align-items:center">
                    <span class="circle margin-top"><b style="color: black;">0</b></span>
                    <strong class="pt-3 hb-font-body-medium">To speak with an Agent, press 0.</strong>
                  </div>
                </template>
              </hb-form>
            </template>
            <template v-slot:footer>
              <hb-bottom-action-bar @close="cancelStudioFlow" >
                
                <template v-slot:right-actions>
                  <hb-btn :disabled="!alternateMenuTenant" @click="alternateMenuTenantToggle" color="secondary" large>Reset Menu Order</hb-btn>
                  <hb-btn :disabled="!isAutoAttendantEnabled" @click="editStudioFlow()">Save</hb-btn>
                </template>
              </hb-bottom-action-bar>
            </template>
          </hb-expansion-panel>
          
          <!--  Text-to-Speech expansion panel -->
          <hb-expansion-panel v-show="selectedMenuOption == 'voice'">
            <template v-slot:title>
              Text-to-Speech
            </template>
            <template v-slot:content>
              <hb-form label="Voice Gender" editable :active.sync="active" @cancel="handleEmittedCancel"
                @save="handleEmittedSave('default.voice_gender')" :empty="!voiceGender" :disable-save="disableSave"
                full>
                <template v-slot:display>
                  {{ defaultVoiceGender == 1 ? "Male" : defaultVoiceGender == 2 ? "Female" : "" }}
                </template>
                <template v-slot:edit>
                  <p>Select the gender of the text-to-speech voice used for Voicemail & Auto-Attendant menus.</p>
                  <div class="ma-4">
                    <hb-radio-group v-model="voiceGender" row v-validate="'required'" data-vv-scope="default"
                      data-vv-name="voice_gender" data-vv-as="voiceGender" :error="errors.has('default.voice_gender')"
                      placeholder="voiceGender">
                      <hb-radio label="Female" value="2" />
                      <hb-radio label="Male" value="1" />
                    </hb-radio-group>
                  </div>
                </template>
              </hb-form>
            </template>
          </hb-expansion-panel>
        </v-expansion-panels>
      </div>
      <v-navigation-drawer v-model="showAside" :right="$vuetify.breakpoint.width > 480"
        :hide-overlay="$vuetify.breakpoint.smAndUp" :clipped="!$vuetify.breakpoint.xs"
        :class="{ 'attached-drawer': $vuetify.breakpoint.width > 480 }" disable-resize-watcher mobile-breakpoint="480"
        class="ma-0 pa-0 hb-tertiary-navigation charm-sidebar-wrapper" :width="showAside ? '250px' : '0px'"
        :bottom="$vuetify.breakpoint.width <= 480" :app="$vuetify.breakpoint.width <= 480" flat>
        <v-list>
          <v-list-item v-for="(option) in getMenuOptions()" :key="option.key" @click="selectOptionFunction(option.key)"
            :class="{ 'selected': selectedMenuOption === option.key }">
            <v-list-item-content>
              <v-list-item-title style="font-size: 15px; display: flex; flex-direction: column;"><span>{{
                option.text }}</span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import draggable from 'vuedraggable';
import api from "../../../assets/api.js";
import textToSpeech from "./textToSpeech.vue";
export default {
  name: "CharmNewUiIndex",
  mixins: [notificationMixin],
  components: {
    draggable,
    textToSpeech
  },
  data() {
    return {
      panel: [],
      showAside: true,
      isAutoAttendantEnabled: false,
      alternateMenuTenant: false,
      propertyId: null,
      disableSave: false,
      active: "",
      voiceGender: null,
      defaultVoiceGender: null,
      GreetingMessage: "",
      defaultGreetingMessage: "",
      ringTime: 20,
      loadingDocuments: false,
      default_auto_attendant: [
        { id: 1, action: "ring_phone", action_name: "Ring Phone", title: "New Lead",widget_name:"CONNECT_CHARM_1",call_to_action: "If you are interested in renting storage", action_id: 1 , uniqueId:"" },
        { id: 2, action: "phone_payment", action_name: "Phone Payment", title: " ",widget_name:"RUN_PAYMENT_FLOW_1",call_to_action: "To pay your rent by phone", action_id: 2 , uniqueId:""},
        { id: 3, action: "gate_code", action_name: "Gate Code by Text", title: " ",widget_name:"RUN_GATE_FLOW_1", call_to_action: "To receive your gate code by text", action_id: 3 ,uniqueId:""},
        { id: 4, action: "text_to_speech", action_name: "Text To Speech", title: "Business Hours",widget_name:"SAY_TEXT", call_to_action: "To hear our business hours", text_to_speech: "Our leasing office is open seven days a week. Seven to seven.", action_id: 4 ,uniqueId:""},
        { id: 5, action: "ring_phone", action_name: "Ring Phone", title: "Existing Tenant",widget_name:"CONNECT_CHARM_1",call_to_action: "If you are a current tenant and need to speak to someone", action_id: 1 , uniqueId:"" },
      ],
      default_greeting_message_auto_attendant: "Thankyou for calling Store local storage",
      auto_attendant_menu: [],
      greeting_message_auto_attendant: "",
      auto_attendant_menu_tenant:[],
      flow_sid:"",
      flow_gds_facility_id:"",
      auto_attendant_options: [
        { id: 1, action: "ring_phone", action_name: "Ring Phone", title: "",widget_name:"CONNECT_CHARM_1", subtitle: " ", call_to_action: " ", action_id: 1 , uniqueId:""},
        { id: 2, action: "phone_payment", action_name: "Phone Payment", title: "", widget_name:"RUN_PAYMENT_FLOW_1", subtitle: "", call_to_action: " ", action_id: 2 , uniqueId:""},
        { id: 3, action: "gate_code", action_name: "Gate Code by Text", title: "",widget_name:"RUN_GATE_FLOW_1", subtitle: "", call_to_action: " ", action_id: 3 , uniqueId:""},
        { id: 4, action: "text_to_speech", action_name: "Text To Speech", title: "", subtitle: " ",widget_name:"SAY_TEXT", call_to_action: " ", text_to_speech: " ", action_id: 4 , uniqueId:""},
      ],
      menuOptions: [       
        { text: 'Voicemail',key:"voicemail" },
        { text: 'Auto Attendant',key:"auto_attendant" },
        { text: 'Voice' ,key:"voice"},
      ],
      newMethodJson: { action_name: "New Option 1", action_id: null, new: true },
      selectedMenuOption: 'voicemail',
      tabDetails: [
        {
          key: "Property",
          label: "Property Settings"
        }
      ],
      draft:false,
    }
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      hasRolePermission: "authenticationStore/rolePermission",
      charmSubscribedProperties: "charmSubscriptionStore/charmSubscribedProperties",
    }),
  },
  methods: {
    getMenuOptions(){
       if(this.hasRolePermission('charm_auto_attendant')){
        return this.menuOptions
       }else{
        return  [       
        { text: 'Voicemail',key:"voicemail" },
        { text: 'Voice' ,key:"voice"},
      ]
       }
    },
    async fetchVoicemailData() {
      this.loadingDocuments = true
      let propertyGds = this.properties.filter((ele) => {
        return ele.id == this.propertyId;
      });
      await api.get(this.$app, api.CHARM_VOICEMAIL, { "facility": propertyGds[0].gds_id, "property_id": this.propertyId })
        .then((data) => {
          this.defaultGreetingMessage = data.voicemail_text ? data.voicemail_text.trim().replace(/\s+/g, ' ').replace(/\s+(?=[.,?!])/g, '') : "";
          this.defaultVoiceGender = data.voice_type ? data.voice_type : null;
          this.voiceGender = data.voice_type ? data.voice_type : null;
          this.GreetingMessage = data.voicemail_text ? data.voicemail_text.trim().replace(/\s+/g, ' ').replace(/\s+(?=[.,?!])/g, '') : "";
        })
        .catch((err) => {
          console.log(err);
        })
      this.loadingDocuments = false
    },
    async saveVoicemail() {
      let propertyGds = this.properties.filter((ele) => {
        return ele.id == this.propertyId;
      });

      api.post(this, api.CHARM_VOICEMAIL, { "property_id": this.propertyId, "facility": propertyGds[0].gds_id, "voicemail_text": this.GreetingMessage, "voice_type": this.voiceGender })
        .then(() => {
          this.showMessageNotification({ type: "success", description: "Voicemail updated successfully" });
          this.defaultVoiceGender = this.voiceGender
          this.defaultGreetingMessage = this.GreetingMessage
        })
        .catch((err) => {
          console.log(err);
          this.showMessageNotification({ type: "error", description: "Something went wrong" });
        });

    },


    //studio ivr functions
    async fetchStudioFlow() {
      this.loadingDocuments = true
      this.auto_attendant_menu = this.default_auto_attendant 
      this.greeting_message_auto_attendant = this.default_greeting_message_auto_attendant
      await api.get(this.$app, api.IVR_STUDIO, { "property_id": this.propertyId })
        .then((data) => {
          this.flow_sid = data?.flow_sid
          this.flow_gds_facility_id = data?.facility_id
          if(data.status == 'published' && data.flow_json?.menu?.length) {
            let updatedList = data.flow_json.menu;
            updatedList = updatedList.filter(item => item.id !== 9);
            updatedList = updatedList.filter(item => item.id !== 0);
            this.auto_attendant_menu = updatedList
            this.greeting_message_auto_attendant = data.greeting_message
            this.isAutoAttendantEnabled = true
            this.draft = true
          }else if(data.status == 'draft' && data.flow_json?.menu?.length){
            let updatedList = data.flow_json.menu;
            this.draft = true
            updatedList = updatedList.filter(item => item.id !== 9);
            updatedList = updatedList.filter(item => item.id !== 0);
            this.auto_attendant_menu = updatedList
            this.greeting_message_auto_attendant = data.greeting_message
            this.isAutoAttendantEnabled = false
          }
          else{
            this.isAutoAttendantEnabled = false
            this.draft = false
          }
          if(data.tenant_enabled && data.flow_json?.tenant?.length){
            let updatedListTenant = data.flow_json.tenant
            updatedListTenant = updatedListTenant.filter(item => item.id !== 9);
            updatedListTenant = updatedListTenant.filter(item => item.id !== 0);
            this.auto_attendant_menu_tenant = updatedListTenant
            this.alternateMenuTenant = true
          }else{
            this.alternateMenuTenant = false
          }
        })
        .catch((err) => {
          console.log(err);
        })
      this.loadingDocuments = false
    },
    async resetSwitch(){
      setTimeout(()=>{
        this.isAutoAttendantEnabled = false
      },100)
    },
    validate(){
      if(!this.greeting_message_auto_attendant.trim()){
            this.showMessageNotification({ type: "error", description: "Greeting message is required" });
            return
          }
        for(let ele   of this.auto_attendant_menu) {
          if(!ele.action){
            this.showMessageNotification({ type: "error", description: `Please select an action in option ${ele.id}.` });
            return
          }
          if(!ele.title?.trim() && !(ele.action == 'phone_payment' || ele.action == 'gate_code')){
            this.showMessageNotification({ type: "error", description: `Please enter a title in option ${ele.id}.` });
            return
          }
          if(!ele.call_to_action?.trim()){
            this.showMessageNotification({ type: "error", description: `Please enter a call to action in option ${ele.id}.` });
            return
          }
          if(!ele.text_to_speech?.trim() && ele.action == 'text_to_speech') {
            this.showMessageNotification({ type: "error", description: `Please enter a text_to_speech in option ${ele.id}.`});
            return
          }
        }

        return true
    },
    async saveStudioFlow(){
          let validate = this.validate()
          if(!validate){
            this.resetSwitch()
            return
          }
          let data = {}
          data.status = this.isAutoAttendantEnabled ? 'published' : 'draft'
          data.property_id = this.propertyId
          data.greeting_message = this.greeting_message_auto_attendant
          data.flow_details = {}
          data.tenant_enabled = !!this.alternateMenuTenant
          let menu = [...this.auto_attendant_menu]
          let tenant_menu = [...this.auto_attendant_menu_tenant]
          menu.push({ id: 9, action: "repeat_menu",widget_name:"call_landing",call_to_action: "To repeat this menu", action_name: "Repeat Menu" })
          tenant_menu.push({ id: 9, action: "repeat_menu",widget_name:"call_landing",call_to_action: "To repeat this menu", action_name: "Repeat Menu" })
          menu.push({ id: 0, action: "ring_phone", action_name: "Ring Phone" })
          tenant_menu.push({ id: 0, action: "ring_phone", action_name: "Ring Phone" })
          data.flow_json = {
            menu: menu,
          }
          if(this.alternateMenuTenant){
            data.flow_json.tenant = tenant_menu
          }else{
            data.flow_json.tenant = []
          }
        api.post(this, api.IVR_STUDIO, data)
        .then(() => {
          this.showMessageNotification({ type: "success", description: "Studio flow updated successfully" });
          this.fetchStudioFlow()
        })
        .catch((err) => {
          console.log(err);
          this.resetSwitch()
          this.showMessageNotification({ type: "error", description: "Something went wrong" });
        });
    },
    async editStudioFlow(){
          let validate 
          if(this.isAutoAttendantEnabled){
            validate = this.validate()
          }
          if(!validate && this.isAutoAttendantEnabled ){
            return
          }
      let data = {}
          data.status = this.isAutoAttendantEnabled ? 'published' : 'draft'
          data.property_id = this.propertyId
          data.greeting_message = this.greeting_message_auto_attendant
          data.flow_details = {}
          data.tenant_enabled = !!this.alternateMenuTenant
          data.flow_sid = this.flow_sid
          data.flow_name = this.flow_gds_facility_id
          data.facility_id = this.flow_gds_facility_id
          let menu = [...this.auto_attendant_menu]
          let tenant_menu = [...this.auto_attendant_menu_tenant]
          menu.push({ id: 9, action: "repeat_menu",widget_name:"call_landing",call_to_action: "To repeat this menu", action_name: "Repeat Menu" })
          tenant_menu.push({ id: 9, action: "repeat_menu",widget_name:"call_landing",call_to_action: "To repeat this menu", action_name: "Repeat Menu" })
          menu.push({ id: 0, action: "ring_phone", action_name: "Ring Phone" })
          tenant_menu.push({ id: 0, action: "ring_phone", action_name: "Ring Phone" })
          data.flow_json = {
            menu: menu,
          }
          if(this.alternateMenuTenant){
            data.flow_json.tenant = tenant_menu
          }else{
            data.flow_json.tenant = []
          }
        api.put(this, api.IVR_STUDIO, data)
        .then(() => {
            this.showMessageNotification({ type: "success", description: "Auto Attendant updated successfully" });
            this.fetchStudioFlow()
        })
        .catch((err) => {
          console.log(err);
          this.showMessageNotification({ type: "error", description: "Something went wrong" });
        });
    },
    async toggleFeature(){
          let data = {}
          let propertyGds = this.properties.filter((ele) => {
        return ele.id == this.propertyId;
      });
        data.status = this.isAutoAttendantEnabled ? 'published' : 'draft'
        data.facility_id = propertyGds[0].gds_id
        api.patch(this, api.IVR_STUDIO, {...data})
        .then(() => {
            this.showMessageNotification({ type: "success", description: `Auto Attendant ${this.isAutoAttendantEnabled ? "enabled" : "disabled"} successfully` });
        })
        .catch((err) => {
          console.log(err);
          this.showMessageNotification({ type: "error", description: "Something went wrong" });
        });
    },
    autoAttendant() {
        if (this.isAutoAttendantEnabled && !this.draft) {
          this.panel.push(1)
          this.saveStudioFlow();
        } else if(this.isAutoAttendantEnabled && this.draft){
          this.panel.push(1)
          this.toggleFeature();
        }
        else if(!this.isAutoAttendantEnabled && this.draft){
          this.panel = this.panel.filter(ele => ele != 1)  
          this.toggleFeature();
        }
    },

    selectOptionFunction(key) {
      this.selectedMenuOption = key;
    },
    WidthComp() {
      if (this.showAside) {
        return 'width: 100%;overflow-x: hidden;'
      }
    },

    getProperties() {
      return this.properties.filter((data) =>
        this.charmSubscribedProperties.some((ele) => ele.property_id === data.id)
      );
    },
    handleEmittedSave(argument) {
      this.$validator.validate(argument).then(async (valid) => {
        console.log(valid)
        if (!valid) {
          this.showMessageNotification({
            type: "error",
            list: this.errors.items,
            description:
              "There are errors in your form, correct them before continuing.",
          });
          return;
        }
        if (argument == 'default.voice_gender' || argument == 'default.greeting_message') {
          this.active = 'saved'
          this.saveVoicemail()
        }

      }
      )
    },
    handleEmittedCancel(label) {
      if (label == 'Voice Gender' || label == 'Voicemail Greeting*') {
        this.voiceGender = this.defaultVoiceGender
        this.GreetingMessage = this.defaultGreetingMessage
      }
    },
    saveNewOrder() {
      for (let i = 0; i < this.auto_attendant_menu.length; i++) {
        this.auto_attendant_menu[i].id = i + 1
      }

    },
    saveNewOrderTenant(){
      for (let i = 0; i < this.auto_attendant_menu_tenant.length; i++) {
        this.auto_attendant_menu_tenant[i].id = i + 1
      }
    },
    removeItem(item) {
      this.auto_attendant_menu = this.auto_attendant_menu.filter(data => {
        return data.id != item.id
      })
    },
     randomString (length = 10) {
          return Array.from({length}, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt((Math.floor(Date.now() * Math.random()) % 62))).join('')
     },
    updateList(item_id, auto_attendant_id,uniqueId) {
      if (item_id) {
        let change = [...(this.auto_attendant_menu)]
        change[auto_attendant_id - 1] = JSON.parse(JSON.stringify(this.auto_attendant_options[item_id - 1]));
        change[auto_attendant_id - 1].id = auto_attendant_id
        change[auto_attendant_id - 1].uniqueId = uniqueId
        this.auto_attendant_menu = [...change]
      } else {
        let change = [...(this.auto_attendant_menu)]
        change[auto_attendant_id - 1] = JSON.parse(JSON.stringify(this.newMethodJson));
        change[auto_attendant_id - 1].id = auto_attendant_id
        change[auto_attendant_id - 1].uniqueId = this.randomString()
        this.auto_attendant_menu = [...change]
      }

    },

    addNewOption() {
      if (this.auto_attendant_menu.length == 8) {
        this.showMessageNotification({
          type: "error",
          description:
            "You can only add up to 8 actions.",
        });
        return;
      }
      let index = this.auto_attendant_menu.length + 1
      let newMethod = JSON.parse(JSON.stringify(this.newMethodJson))
      newMethod.id = index
      newMethod.uniqueId =  this.randomString()
      this.auto_attendant_menu.push(newMethod);
    }
    ,
    alternateMenuTenantToggle(){
      this.auto_attendant_menu.map(ele=>{
        ele.uniqueId =  this.randomString()
      })
      if(this.alternateMenuTenant){
          this.auto_attendant_menu_tenant = [...this.auto_attendant_menu]
      }else{
          this.auto_attendant_menu_tenant = []
      }
    },
    restData(){
      this.panel= [],
      this.isAutoAttendantEnabled= false
      this.alternateMenuTenant= false
      this.voiceGender= null
      this.defaultVoiceGender= null
      this.GreetingMessage= ""
      this.defaultGreetingMessage=""
      this.ringTime= 20
      this.default_greeting_message_auto_attendant= "Thankyou for calling Store local storage"
      this.auto_attendant_menu = []
      this.greeting_message_auto_attendant = ""
      this.auto_attendant_menu_tenant=[],
      this.selectedMenuOption= 'voicemail'
      this.flow_sid=""
      this.flow_gds_facility_id = ""
      this.draft = false
    },
    cancelStudioFlow(){
      this.fetchStudioFlow();
    },

  },
  watch: {
    propertyId() {
      this.restData();
      this.fetchVoicemailData();
      this.fetchStudioFlow();
    },
    auto_attendant_menu: {
    handler: async function () {
      if (this.alternateMenuTenant) {
    this.auto_attendant_menu.forEach((ele) => {
    const { uniqueId } = ele;
    const index = this.auto_attendant_menu_tenant.findIndex(data => data.uniqueId === uniqueId);
    if (index !== -1) {
        this.auto_attendant_menu_tenant[index] = { ...ele }; 
        this.auto_attendant_menu_tenant = [...this.auto_attendant_menu_tenant]
    } else {
        this.auto_attendant_menu_tenant.push({ ...ele }); 
    }
  
});
this.auto_attendant_menu_tenant = this.auto_attendant_menu_tenant.filter((tenantEle) => {
                return this.auto_attendant_menu.some((menuEle) => menuEle.uniqueId === tenantEle.uniqueId);
            });
}
    },
    deep: true
  }
  }
};
</script>

<style lang="scss" scoped>
.panel-wrapper {
  display: flex;
  margin-bottom: 10px;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #E0F5F5;

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-weight: bold;
  flex-shrink: 0;
}

.panel-container {
  flex: 1;
  padding-bottom: 13px;

}

.margin-top {
  margin-top: 12px;
}

.margin-top-2 {
  margin-top: 16px;
}

.section-content-charm {
  background: rgb(249, 250, 251);
}

.charm-settings-sticky {
  position: sticky;
  top: 60px;
  z-index: 4;
  background-color: #f9fafb;
}

.primary-section-content-charm {
  display: flex;
  height: calc(100% - 70px);
  flex-direction: row;
  position: relative;

  &>div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.charm-sidebar-wrapper {
  z-index: 3 !important;
  height: 100vh !important;
  height: auto !important;
}


.selected {
  background: #e0f5f5;
  border-left: 4px solid #00848E;
  width: 100%
}

  .alternateTenantCard{
    border: 1px solid #DFE3E8;
    display: flex;
    align-items: center;
  }
</style>